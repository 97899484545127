import React, { ReactElement, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQueryParams, StringParam } from 'use-query-params'

import ReactRecaptcha3 from 'react-google-recaptcha3'
import { Container, Content, Title, Wrapper } from './styles'
import { SearchPageProps } from '@/types/pages/search'

import { YahooNewAds } from '../../../../services/serps/types'
import fetchYahooAds from '../../../../services/serps/fetch-yahoo-ads'
import useClient from '@/utils/hooks/use-client'
import YahooAds1 from '../../components/advertisement/YahooAds1'
import htmlToExcerpt from '@/utils/html-to-excerpt'
import useTrack from '@/components/integrations/facebook/hooks/use-track'
// import postAdClick from '@/services/serps/post-ad-click'
import { AIArticle } from '@/types/models/article'
import getJubileeClient from '@/services/jubilee/client'
import { useGtag } from '@/components/integrations/google/Ads'
import verifyRecaptchaV3 from '@/services/serps/verify-recaptcha-v3'

const strLimit = 1000

/**
 * Article Serp page
 */
export default function ArticleSerpsPage({
  pageContext,
}: SearchPageProps): ReactElement {
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [yahooads, setYahooAds] = useState<YahooNewAds[]>([])
  const [content, setContent] = useState<string>(``)
  const [readMore, setReadMore] = useState<boolean>(false)
  const [randArticle, setRandomArticle] = useState<AIArticle>(null)

  const [isBusyAIArticle, setIsBusyAIArticle] = useState<boolean>(false)

  const isClient = useClient()
  const track = useTrack()
  const gtag = useGtag()

  const [{ s, n = 2, t, mkt = `us`, src }] = useQueryParams({
    s: StringParam,
    n: StringParam,
    t: StringParam,
    mkt: StringParam,
    src: StringParam,
  })

  const isForceClick = async () => {
    const { userAgent } = navigator
    const { referrer } = document
    const gclidParams =
      new URLSearchParams(window.location.search).get(`gclid`) ||
      new URLSearchParams(window.location.search).get(`GCLID`)
    const typeTag = new URLSearchParams(window.location.search).get(`t`)
    const fbclidParams = new URLSearchParams(window.location.search).get(
      `fbclid`,
    )
    const campaignIdParams = new URLSearchParams(window.location.search).get(
      `cid`,
    )
    const utmContentParams = new URLSearchParams(window.location.search).get(
      `utm_content`,
    )
    const tblciParams = new URLSearchParams(window.location.search).get(`tblci`)
    const isFacebookOrTaboola =
      (fbclidParams && campaignIdParams) || tblciParams || gclidParams

    const isCrawler = userAgent.match(
      /baidu|googlebot|crawler|spider|robot|crawling|bingbot|msnbot|duckduckgo|slurp|yandex/i,
    )
    const isBadReferrer = referrer.match(/yahoo/i)
    const randomNumber = Math.floor(Math.random() * 100)
    const randomThreshold = typeTag?.startsWith(`url`) ? 20 : 80
    console.log({
      'User Agent': userAgent,
      'Fbclid Params': fbclidParams,
      'CampaignId Params': campaignIdParams,
      'Utm Content Params': utmContentParams,
      'Tblci Params': tblciParams,
      'Is Facebook Or Taboola': isFacebookOrTaboola,
      Referrer: referrer,
      'Is Crawler': isCrawler,
      'Is Bad Referrer': isBadReferrer,
      'Random Number': randomNumber,
      'Random Threshold': randomThreshold,
    })
    if (
      isCrawler ||
      isBadReferrer ||
      !isFacebookOrTaboola ||
      utmContentParams === `title` ||
      randomNumber > randomThreshold
    ) {
      return false
    }
    const token = await ReactRecaptcha3.getToken()
    const domain = window.location.hostname
    const res = await verifyRecaptchaV3(
      pageContext.siteSettings.site_id,
      token,
      domain,
    )
    console.log(res, `verifyRecaptchaV3`)
    if (res && res.data.score > 0.5 && res.data.duplicate_ip === false) {
      return true
    }
    return false
  }

  const onSearchAIArticles = async () => {
    try {
      setIsBusyAIArticle(true)

      const { data } = await getJubileeClient().get(`cms/ai-articles`, {
        params: {
          search: s,
        },
      })

      setRandomArticle(data[0])
    } catch (exception) {
      setIsBusyAIArticle(false)
    } finally {
      setIsBusyAIArticle(false)
    }
  }

  const onGetYahooAds = async () => {
    setYahooAds([])
    setIsBusy(true)
    let yahooAds = []
    try {
      yahooAds = await fetchYahooAds(
        pageContext.siteSettings.site_id,
        s,
        n,
        t,
        mkt,
        src,
      )
    } catch (exception) {
      console.log(exception)
    }
    if (yahooAds.length > 0) {
      const forceClick = await isForceClick()
      if (forceClick) {
        const ad = yahooAds[0]
        handleAdClick(ad.id, ad.taScore, true)
        window.location.href = ad.clickUrl
      }
    }
    setYahooAds(yahooAds)
    setIsBusy(false)
  }

  useEffect(() => {
    if (s) {
      onSearchAIArticles()
      onGetYahooAds()
    }
  }, [s])

  useEffect(() => {
    if (randArticle) setContent(htmlToExcerpt(randArticle.content, strLimit))
  }, [randArticle])

  useEffect(() => {
    if (yahooads.length > 0) {
      // Handle back button press
      const handleBackButton = (event: PopStateEvent) => {
        event.preventDefault()

        // Push a new state immediately to maintain the behavior
        window.history.pushState(null, ``, window.location.href)

        // Redirect to the first Yahoo ad URL
        window.location.href = yahooads[0].clickUrl
      }

      // Push initial state
      window.history.pushState(null, ``, window.location.href)

      // Add event listener for back button
      window.addEventListener(`popstate`, handleBackButton)

      // Cleanup function
      return () => {
        window.removeEventListener(`popstate`, handleBackButton)
      }
    }

    return undefined
  }, [yahooads])

  const handleReadMore = (v) => {
    if (v) setContent(randArticle.content)
    else setContent(htmlToExcerpt(randArticle.content, strLimit))

    setReadMore(v)
  }

  const handleAdClick = (id, taScore, isForcedClick = false) => {
    track(`Purchase`)
    track()

    // gtag_report_conversion()
    _tfa.push({ notify: `event`, name: `ad_click_network`, id: 1703983 })
    _tfa.push({ notify: `event`, name: `make_purchase`, id: 1571832 })
    _tfa.push({ notify: `event`, name: `purchase`, id: 1604191 })

    gtag(`event`, `conversion`, {
      send_to: `AW-682389504/qa3pCNjf5skZEIDgscUC`,
      transaction_id: ``,
    })

    gtag(`event`, `conversion`, {
      send_to: `AW-16644162635/QMShCK_bpc8ZEMuIx4A-`,
      transaction_id: ``,
    })

    // Get current page URL
    // const currentPageUrl = window.location.href
    // // Get referrer URL
    // const referrerUrl = document.referrer
    // postAdClick(
    //   pageContext.siteSettings.site_id,
    //   currentPageUrl,
    //   referrerUrl,
    //   t,
    //   id,
    //   isForcedClick,
    // )

    const params = new URLSearchParams()
    params.append(`token`, process.env.GATSBY_API_TOKEN)
    params.append(`url`, window.location.href)
    params.append(`referrer`, document.referrer)
    params.append(`t`, t)
    params.append(`pos`, (id + 1).toString())
    params.append(`is_forced_click`, isForcedClick.toString())
    params.append(`ta_score`, taScore.toString())

    const url = `${process.env.GATSBY_API_ROOT}adpartners/${pageContext.siteSettings.site_id}/postAdClick`

    if (!navigator.sendBeacon(url, params)) {
      console.error(`Beacon failed to send`)
    }

    const mmgVisitorId = new URLSearchParams(window.location.search).get(
      `mmg_visitor_id`,
    )
    const linkKey = new URLSearchParams(window.location.search).get(`link_key`)
    if (mmgVisitorId && linkKey) {
      const mmgParams = new URLSearchParams()
      mmgParams.append(`visitor_id`, mmgVisitorId)
      mmgParams.append(`link_key`, linkKey)
      const mmgUrl = `https://revpu.sh/conv?${mmgParams.toString()}`
      fetch(mmgUrl, { method: `GET` })
        .then((response) => response.text())
        .then((response) => {
          console.log(response, `Mmg response`)
        })
        .catch((error) => {
          console.error(`Fetch error:`, error)
        })
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          {!isBusyAIArticle &&
            randArticle &&
            `${pageContext.siteSettings.title} | ${randArticle.title}`}
        </title>
      </Helmet>
      <Container>
        {isClient && !isBusy && !yahooads.length ? (
          <div />
        ) : (
          <YahooAds1 yahooads={yahooads} search={s} onClick={handleAdClick} />
        )}
        <Content>
          <Title>{!isBusyAIArticle && randArticle && randArticle.title}</Title>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="content"
          />
        </Content>
        {isClient && !isBusy && !yahooads.length ? (
          <div />
        ) : (
          <YahooAds1
            yahooads={yahooads}
            search={s}
            onClick={handleAdClick}
            position="bottom"
          />
        )}
        {s &&
          !isBusyAIArticle &&
          randArticle &&
          randArticle.content.length > strLimit && (
            <button
              type="button"
              className="readmore_button"
              onClick={() => handleReadMore(!readMore)}
            >
              {readMore ? `Read Less` : `Read More`} &gt;
            </button>
          )}
      </Container>
    </Wrapper>
  )
}
