import { navigate } from 'gatsby'
import React from 'react'
import { AIArticle } from '@/types/models/article'
import { SubArticlesContainer } from './styles'

interface SubArticlesProps {
  subArticles: AIArticle[]
}

const SubArticles = ({ subArticles }: SubArticlesProps) => {
  const handleSubArticleClick = (keyword: string) => {
    // Extract current query parameters
    const currentSearchParams = new URLSearchParams(window.location.search)

    // Set the new "s" parameter
    currentSearchParams.set(`s`, keyword)

    // Navigate to /search/top5 with updated query parameters
    navigate(`/search/top5?${currentSearchParams.toString()}`)
  }

  if (!subArticles.length) return null

  return (
    <SubArticlesContainer>
      <div className="title">You Might Also Lke</div>
      <div className="sub-articles">
        {subArticles?.map((article) => (
          <div
            className="sub-article"
            onClick={() => handleSubArticleClick(article.keyword)}
            role="button" // Specifies that this element behaves like a button
            tabIndex={0} // Makes the element focusable via keyboard
            onKeyDown={(e) => {
              if (e.key === `Enter` || e.key === ` `) {
                handleSubArticleClick(article.keyword)
              }
            }}
            key={`${article.title}-${getRandomNumber()}`}
          >
            <div className="article-img-container">
              <img
                className="article-img"
                src={article.image_url}
                alt={article.title}
              />
            </div>
            <div className="content">
              <div className="category">{article.category}</div>
              <div className="title">{article.title}</div>
            </div>
          </div>
        ))}
      </div>
    </SubArticlesContainer>
  )
}

export default SubArticles

function getRandomNumber(min = 0, max = 1000000): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
