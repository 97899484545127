import React, { ReactElement, useMemo, useState } from 'react'

import { ArticleContent, ArticleContainer } from './styles'
import { AIArticleProps } from './types'
import { shortenText } from '@/utils/shorten-text'
import UserImage from '@/assets/images/user.png'
import LogoFacebook from '@/assets/images/facebook-svgrepo-com.svg'
import LogoTwitter from '@/assets/images/twitter-svgrepo-com.svg'
import LogoPinterest from '@/assets/images/pinterest-svgrepo-com.svg'
import LogoEmail from '@/assets/images/email-svgrepo-com.svg'
import useMediaQuery from '@/utils/hooks/use-media-query'

export default function AIArticleItem({
  aiArticle,
}: AIArticleProps): ReactElement {
  const [isTruncated, setIsTruncated] = useState(
    aiArticle.content.length > 1500,
  )
  const isDesktop = useMediaQuery(`(min-width: 768px)`)

  const text = useMemo(
    () =>
      isTruncated ? shortenText(aiArticle.content, 1500) : aiArticle.content,
    [isTruncated, aiArticle],
  )

  const handleClickShowMore = () => {
    setIsTruncated(false)
  }

  const randomDate = getRandomDateBetweenOneAndFiveYearsAgo()

  const formattedDate = new Intl.DateTimeFormat(`en-US`, {
    month: `short`,
    day: `2-digit`,
    year: `numeric`,
  }).format(randomDate)

  return (
    <ArticleContainer>
      <div className="category">{aiArticle.category}</div>
      <div className="title">{aiArticle.title}</div>
      <div className="author-container">
        <div className="sec1">
          {isDesktop && (
            <img className="avatar-img" src={UserImage} alt="User Avatar" />
          )}
          By <strong className="author-name">{aiArticle.author}</strong>
          &nbsp;&nbsp;|&nbsp;&nbsp;Published on {formattedDate}
        </div>

        <div className="social-logo">
          <div className="social-icon">
            <img src={LogoFacebook} alt="facebook logo" />
          </div>
          <div className="social-icon">
            <img src={LogoTwitter} alt="twitter logo" />
          </div>
          <div className="social-icon">
            <img src={LogoPinterest} alt="pinterest logo" />
          </div>
          <div className="social-icon">
            <img src={LogoEmail} alt="email logo" />
          </div>
        </div>
      </div>
      <ArticleContent>
        <div className="article-img-container">
          {` `}
          <img
            className="article-img"
            src={aiArticle.image_url}
            alt={aiArticle.title}
          />
        </div>
        <div className="content-container">
          <div dangerouslySetInnerHTML={{ __html: text }} className="content" />
        </div>
        {isTruncated && (
          <button
            type="button"
            onClick={handleClickShowMore}
            style={{
              cursor: `pointer`,
              marginTop: `1rem`,
              display: `block`,
              border: 0,
              padding: 0,
              backgroundColor: `transparent`,
            }}
          >
            Click Here to Read More of the Article {`>`}
          </button>
        )}
      </ArticleContent>
    </ArticleContainer>
  )
}

function getRandomDateBetweenOneAndFiveYearsAgo(): Date {
  const now = new Date()
  const maxOffset = 5 * 365 * 24 * 60 * 60 * 1000 // 5 years in milliseconds
  const minOffset = 1 * 365 * 24 * 60 * 60 * 1000 // 1 year in milliseconds

  // Calculate a random offset between 1 and 5 years ago
  const randomOffset =
    Math.floor(Math.random() * (maxOffset - minOffset)) + minOffset
  return new Date(now.getTime() - randomOffset)
}
