import React, { ReactElement, useEffect, useState } from 'react'
import AIArticle from './AIArticle'
import { SearchArticlesWrapper, NoArticles } from './styles'
import { AIArticlesProps } from './types'
import useClient from '@/utils/hooks/use-client'
import useMediaQuery from '@/utils/hooks/use-media-query'
import SubArticles from './SubArticles'

export default function SearchAIArticles({
  aiArticles = [],
  subArticles,
}: AIArticlesProps): ReactElement {
  const [page, setPage] = useState<number>(1)
  const isClient = useClient()
  const isDesktop = useMediaQuery(`(min-width: 768px)`)
  useEffect(() => {
    setPage(1)
  }, [aiArticles])

  const currentArticle = aiArticles[page - 1]

  return (
    <SearchArticlesWrapper>
      {isClient && !aiArticles.length ? (
        <NoArticles>No article(s) found</NoArticles>
      ) : (
        currentArticle && (
          <div className="container">
            <AIArticle aiArticle={currentArticle} />
            {isDesktop && <SubArticles subArticles={subArticles} />}
          </div>
        )
      )}
    </SearchArticlesWrapper>
  )
}
